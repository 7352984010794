<template>
  <div class="wrap">
    <NavBar :navSelIndex="2"></NavBar>
    <b-carousel
      id="carouselExampleControls"
      :interval="4000"
      controls
      background="#ababab"
    >
      <!-- Text slides with image -->
      <b-carousel-slide
        v-for="item in banners"
        v-bind:key="item.banner_path"
        :img-src="item.banner_path"
        @click.native="goPack(item.url)"
      >
      </b-carousel-slide>
    </b-carousel>

    <b-container class="container-package">
      <b-row class="style-box">
        <b-col sm="3" md="2" lg="2" xl="1" class="style-title"> 风格: </b-col>
        <b-col sm="9" md="10" lg="10" xl="11">
          <b-row>
            <b-col
              sm="2"
              md="2"
              lg="2"
              xl="1"
              class="style-title style-item"
              @click="goStyle({ id: '' })"
            >
              <span class="title" :class="[styleId == '' ? 'sel' : '']"
                >全部</span
              >
            </b-col>
            <b-col
              sm="2"
              md="2"
              lg="2"
              xl="1"
              class="style-item"
              v-for="(item, index) in styleLists"
              :key="index"
              @click="goStyle(item)"
            >
              <img :src="item.icon" alt="" />
              <span class="title" :class="[styleId == item.id ? 'sel' : '']">{{
                item.name
              }}</span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row
        class="container-row"
        v-for="(item, index) in list"
        :key="index"
        @click="toPackageDetail(item)"
      >
        <b-col class="box" sm="12" md="12" lg="12" xl="12">
          <b-row class="box-row">
            <b-col sm="6" md="6" lg="6" xl="6" class="box-col">
              <img :src="item.lroom_img" class="img" />
            </b-col>
            <b-col class="box-col" sm="6" md="6" lg="6" xl="6">
              <div class="title">{{ item.setmeal_name }}</div>
              <div class="subtitle">包含空间:客厅 主卧 次卧 餐厅</div>
              <div class="des">
                <div class="left">套餐介绍:</div>
                <div class="right">{{ item.setmeal_introduce }}</div>
              </div>
              <div class="counts">包含商品: {{ item.counts }}件</div>
              <b-row class="small-img-box">
                <b-col sm="3" md="3" lg="3" xl="3" class="small-img-col">
                  <img :src="item.lroom_img" class="small-img" />
                </b-col>
                <b-col sm="3" md="3" lg="3" xl="3" class="small-img-col">
                  <img :src="item.mroom_img" class="small-img" />
                </b-col>
                <b-col sm="3" md="3" lg="3" xl="3" class="small-img-col">
                  <img :src="item.restaurant_img" class="small-img" />
                </b-col>
                <b-col sm="3" md="3" lg="3" xl="3" class="small-img-col">
                  <img :src="item.bedroom_img" class="small-img" />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="sorter">
        <b-col cols="12">
          <el-pagination
            v-if="totalPage > 0"
            :page-count="totalPage"
            @current-change="changeCurrentPage"
            class="box-padding"
            background
            layout="prev, pager, next"
          ></el-pagination>
        </b-col>
      </b-row>
    </b-container>
    <Right></Right>
    <Footer></Footer>
  </div>
</template>
<script>
import NavBar from "@/components/Navbar.vue";
import Right from "@/components/Right.vue";
import Footer from "@/components/Footer.vue";
import dump from "../../util/dump.js";
export default {
  components: {
    NavBar,
    Right,
    Footer,
  },
  data() {
    return {
      banners: [],
      list: [],
      styleId: "",
      totalPage: 0,
      currentPage: 1,
     styleLists: [
        {
          icon: require("../../../public/icon/b1 (1).png"),
          name: "极简",
          id: "16",
        },
        {
          icon: require("../../../public/icon/b1 (2).png"),
          name: "轻奢",
          id: "15",
        },
        {
          icon: require("../../../public/icon/b1 (3).png"),
          name: "中式",
          id: "53",
        },
        {
          icon: require("../../../public/icon/b1 (4).png"),
          name: "美式",
          id: "52",
        },
        {
          icon: require("../../../public/icon/b1 (5).png"),
          name: "北欧/现代",
          id: "54",
        },
        {
          icon: require("../../../public/icon/b1 (6).png"),
          name: "英式",
          id: "55",
        },
        {
          icon: require("../../../public/icon/b1 (7).png"),
          name: "欧式/新古典",
          id: "56",
        },
      ],
      keywords:''
    };
  },
  mounted() {
    if (this.$route.query.keyWords) {
		this.keywords = this.$route.query.keyWords;
	}
    this.getBanners();
    this.setmealList(1);
  },
  methods: {
    goPack(url) {
      console.log("123");
      let data = {
        type: 3,
        link: url,
      };
      dump.link(data);
    },
    getBanners() {
      const that = this;
      that.$api
        .banners({
          banner_type: 3,
        })
        .then((res) => {
          that.banners = res.data;
        });
    },
    setmealList(pageNum) {
      const _self = this;
      this.$api
        .setmealList({
          currentPage: pageNum,
          style_id: _self.styleId,
          keywords:_self.keywords
        })
        .then((res) => {
          _self.list = res.data.setmeals;
          _self.totalPage = res.data.totalPage;
          console.log(_self.list);
        });
    },
    changeCurrentPage(info) {
      this.currentPage = info;
      this.setmealList(info);
      this.$utils.scrollTop();
    },
    goStyle(item) {
      const _self = this;
      _self.styleId = item.id;
      _self.setmealList(1);
    },
    toPackageDetail(item) {
      let data = {
        type: 1,
        link: "/packageDetails",
        params: {
          setmeal_id: item.setmeal_id,
        },
      };
      dump.link(data);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/style.scss";
.wrap{
  background: #f2f2f2;
}
.container-package {
  .box-padding {
    margin: 20px;
  }

  .style-box {
    padding: 1.1875rem 0;
    background: #ffffff;
    .style-title {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .style-item {
      padding-left: 0;
      padding-right: 0;
      cursor: pointer;
      img{
        padding: 0 .9375rem;
      }
      .title {
        font-size: 12px;
      }

      .sel {
        color: #d19d7e;
      }
    }
  }
  .sorter{
    background: #fff;
    margin-top: 1.25rem;
  }
  /deep/ .el-pagination {
    .el-pager {
      li:not(.disabled).active {
        background-color: $bg-default-s-color;
        color: #fff;
      }

      li:not(.disabled):hover {
        color: $bg-default-s-color;
      }

      li:not(.disabled).active:hover {
        color: #fff;
      }
    }
  }

  @keyframes test {
    40% {
      transform: scale(1.02);
    }

    100% {
      transform: scale(1);
    }
  }

  .container-row:hover {
    animation-name: test;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-iteration-count: 1;
  }

  .container-row {
    margin-top: 20px;
    cursor: pointer;

    .box {
      .box-row {
        background: #ffffff;
        padding: .75rem;

        .box-col {
          padding-left: 0;

          .img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .title {
            text-align: left;
            padding-top: 18px;
            color: #252220;
            font-size: 18px;
            font-weight: bold;
            box-sizing: border-box;
            margin-bottom: 12px;
          }

          .subtitle {
            text-align: left;
            color: #42413f;
            font-size: 14px;
            box-sizing: border-box;
            margin-bottom: 24px;
          }

          .des {
            display: flex;
            text-align: left;

            .left {
              margin: 0 auto 0 auto;
              text-align: left;
              min-width: 80px;
              font-size: 16px;
              color: #808080;
            }

            .right {
              margin: auto;
              text-align: left;
              font-size: 16px;
              color: #808080;
            }
          }

          .counts {
            text-align: left;
            color: #42413f;
            font-size: 14px;
            box-sizing: border-box;
            padding-top: 20px;
          }

          .small-img-box {
            padding: 0 6px;

            .small-img-col {
              padding-left: 2px;
              padding-right: 2px;

              .small-imgp {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
  }
}
</style>
